import React, { useEffect, useState, Fragment } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import TextElementBlock from "../components/blocks/TextElementBlock.js"
import IntroBlock from "../components/blocks/IntroBlock"
import AttentionBoxBlock from "../components/blocks/AttentionBoxBlock"
import ImageBlock from "../components/blocks/ImageBlock"
import FaqsBlock from "../components/blocks/FaqsBlock"
import TextBlock from "../components/blocks/TextBlock"
import TextColumnsBlock from "../components/blocks/TextColumnsBlock"
import ColumnsBlock from "../components/blocks/ColumnsBlock"
import ButtonBlock from "../components/blocks/ButtonBlock"

import FileUpload from "../components/blocks/FileUpload"

import RequestForm from "../components/RequestForm"
import Seo from "../components/seo"
import PageIndexNav from "../templates/PageIndexNav"
import { isBrowser } from "../utils/utils"

const ArticleTemplate = ({ data }) => {
  const [titles, setTitles] = useState([])
  const [windowDimensions, setDimensions] = useState({
    width: null,
  })

  useEffect(() => {
    if (isBrowser) setDimensions({ width: window.innerWidth })
    const textBlockTitles = []
    data.strapiArticle.content.map(contentBlock => {
      if (contentBlock.strapi_component === "blocks.text-block") {
        if (contentBlock.title) {
          return textBlockTitles.push(contentBlock.title)
        }
      }
      if (contentBlock.strapi_component === "blocks.text-columns") {
        if (contentBlock.title) {
          return textBlockTitles.push(contentBlock.title)
        }
      }
      if (contentBlock.strapi_component === "blocks.box-columns") {
        if (contentBlock.title) {
          return textBlockTitles.push(contentBlock.title)
        }
      }
      if (contentBlock.strapi_component === "blocks.upload-block") {
        if (contentBlock.Title) {
          return textBlockTitles.push(contentBlock.Title)
        }
      }
      return null
    })
    setTitles(textBlockTitles)
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setDimensions({ width: window.innerWidth })
    }
    window.addEventListener("resize", handleResize)
  }, [])

  return (
    <Layout>
      {windowDimensions.width >= 1200 && titles.length > 2 && (
        <PageIndexNav titles={titles} offset={65} wideScreen={true} />
      )}
      <div className="main-col-one">
        <div className="article-page">
          {data.strapiArticle.intro && (
            <Fragment>
              <Seo title={data.strapiArticle.intro.title} />
              <IntroBlock content={data.strapiArticle.intro} />
            </Fragment>
          )}
          {windowDimensions.width < 1200 && titles.length > 2 && (
            <PageIndexNav
              titles={titles}
              offset={titles.length * -33}
              wideScreen={false}
            />
          )}

          {data.strapiArticle.content.map((contentBlock, key) => {
            if (contentBlock.strapi_component === "blocks.text-block") {
              return <TextBlock content={contentBlock} key={key} />
            } else if (
              contentBlock.strapi_component === "blocks.attention-box"
            ) {
              return <AttentionBoxBlock content={contentBlock} key={key} />
            } else if (contentBlock.strapi_component === "elements.image") {
              return <ImageBlock content={contentBlock} key={key} />
            } else if (contentBlock.strapi_component === "elements.text") {
              return <TextElementBlock content={contentBlock} key={key} />
            } else if (contentBlock.strapi_component === "blocks.faq") {
              return <FaqsBlock content={contentBlock} key={key} />
            } else if (contentBlock.strapi_component === "blocks.text-columns") {
              return <TextColumnsBlock content={contentBlock} key={key} />
            } else if (contentBlock.strapi_component === "blocks.box-columns") {
              return <ColumnsBlock content={contentBlock} key={key} />
            } else if (contentBlock.strapi_component === "blocks.upload-block") {
              return <FileUpload content={contentBlock} key={key} />
            } else if (contentBlock.strapi_component === "blocks.button") {
              return <ButtonBlock content={contentBlock} key={key} />
            }

            return null
          })}
          {data.strapiArticle.navigation.path === "request-form" && (
            <RequestForm />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query ArticleTemplate($id: String!) {
    strapiArticle(id: { eq: $id }) {
      title
      intro {
        label
        title
        subtitle
        introduction {
          content
        }
      }
      content
      navigation {
        path
      }
    }
  }
`
