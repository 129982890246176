import React from 'react';

const FileUpload = ({ content }) => {
  return (
    <div className="file-upload block">    
      <div className="title">
        {content.Title && <h2 className="block-title">{content.Title}</h2>}
      </div>
      <div className="files">
        <div className="files-inner">
          {content.Files &&
            content.Files.map((snippet, key) => {
              console.log(snippet);
              return (
                <div className="link" key={key}>
                {snippet.Label && (
                   <a href={process.env.GATSBY_ROOT_URL + snippet.File[0].url} download> {snippet.Label} </a>
                   
                )}
                </div>
              )
            })}
        </div>
      </div>
    </div>
  );
}
 
export default FileUpload;