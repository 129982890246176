import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import InfoIcon from "../assets/icons/Info"

const RequestForm = () => {
  const [isErrorsShowing, setIsErrorsShowing] = useState(false)
  const [isSuccessShowing, setIsSuccessShowing] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const onSubmit = data => {
    console.log(data)
    setIsSuccessShowing(true)
  }

  let errorsArr = []

  const pushErrorToArray = errorMessage => {
    errorsArr.push(errorMessage)
  }

  useEffect(() => {
    if (errorsArr.length > 0) {
      setIsErrorsShowing(true)
    } else {
      setIsErrorsShowing(false)
    }
  }, [errorsArr])

  useEffect(async () => {
    const result = await fetch("./api/formValues.json")
    reset(result)
  }, [reset])

  const ErrorDetails = () => {
    return (
      <div className="error-details-container span-col-2">
        <div className="title-wrap">
          <InfoIcon />
          <div className="error-title">There is an error with the form.</div>
        </div>
        {errorsArr.map((errorMessage, key) => {
          return (
            <div className="error-message" key={key}>
              {errorMessage}
            </div>
          )
        })}
      </div>
    )
  }
  const SubmitSuccess = () => {
    return (
      <div className="submit-success-container span-col-2">
        <div className="title-wrap">
          <InfoIcon />
          <div className="success-title">Form submitted successfully</div>
        </div>
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isErrorsShowing ? <ErrorDetails /> : null}
      {isSuccessShowing ? <SubmitSuccess /> : null}

      <div
        className={errors.companyName ? "input-wrapper error" : "input-wrapper"}
      >
        <label htmlFor="companyName">Company Name *</label>
        <input
          {...register("companyName", { required: "Company Name is required" })}
        />
        {errors.companyName && pushErrorToArray(errors.companyName.message)}
      </div>
      <div className="input-wrapper">
        <label htmlFor="companyAddress">Company Address</label>
        <input {...register("companyAddress")} />
      </div>
      <div className="input-wrapper">
        <label htmlFor="companyWebsite">Company Website</label>
        <input {...register("companyWebsite")} />
      </div>
      <div className="input-wrapper">
        <label htmlFor="companyCountry">Company Country</label>
        <select {...register("companyCountry")}>
          <option value="NZ">New Zealand</option>
          <option value="AU">Australia</option>
          <option value="USA">United States of America</option>
        </select>
      </div>
      <div className="radio">
        <label htmlFor="industrySector">Industry Sector</label>
        <div>
          <input
            type="radio"
            {...register("softwareProvider")}
            value="softwareProvider"
            name="industrySector"
          />
          Software Provider
        </div>
        <div>
          <input
            type="radio"
            {...register("carrier")}
            value="carrier"
            name="industrySector"
          />
          Carrier
        </div>
        <div>
          <input
            type="radio"
            {...register("forwardBroker")}
            value="forwardBroker"
            name="industrySector"
          />
          Forward-Broker
        </div>
      </div>
      <div className="radio">
        <label htmlFor="exisitingTswClient">Exisiting TSW Client</label>
        <div>
          <input
            type="radio"
            {...register("tswYes")}
            value="tswYes"
            name="TSWClient"
          />
          Yes
        </div>
        <div>
          <input
            type="radio"
            {...register("tswNo")}
            value="tswNo"
            name="TSWClient"
          />
          No
        </div>
      </div>
      <div className="input-wrapper">
        <label htmlFor="tswEntityId">TSW Entity ID</label>
        <input {...register("tswEntityId")} />
      </div>
      <div
        className={
          errors.primaryContactName ? "input-wrapper error" : "input-wrapper"
        }
      >
        <label htmlFor="primaryContactName">Primary Contact Name *</label>
        <input
          {...register("primaryContactName", {
            required: "Primary Contact Name is required",
          })}
        />
        {errors.primaryContactName &&
          pushErrorToArray(errors.primaryContactName.message)}
      </div>
      <div className="input-wrapper">
        <label htmlFor="primaryContactPhone">Primary Contact Phone</label>
        <input {...register("primaryContactPhone")} />
      </div>
      <div
        className={
          errors.primaryContactEmail ? "input-wrapper error" : "input-wrapper"
        }
      >
        <label htmlFor="primaryContactEmail">Primary Contact Email *</label>
        <input
          {...register("primaryContactEmail", {
            required: "Primary Contact Email is required",
          })}
        />
        {errors.primaryContactEmail &&
          pushErrorToArray(errors.primaryContactEmail.message)}
      </div>

      <div className="input-wrapper span-col-2">
        <label htmlFor="briefOverview">Brief Overview of Company</label>
        <textarea rows="5" {...register("briefOverview")} />
      </div>

      <div className="radio">
        <label htmlFor="lodgementTypes">Lodgement Types</label>
        <div>
          <input
            type="checkbox"
            value="declarations"
            {...register("declarations")}
          />
          Declarations (IM1/EX1/EXC)
        </div>
        <div>
          <input
            type="checkbox"
            value="cargoReports"
            name="lodgementTypes"
            {...register("cargoReports")}
          />
          Cargo Reports
        </div>
        <div>
          <input
            type="checkbox"
            value="certificates"
            name="lodgementTypes"
            {...register("certificates")}
          />
          Certificates
        </div>
      </div>

      <div className="radio">
        <label htmlFor="uploadChecklist">Upload Checklist</label>
        <div>
          <input type="checkbox" value="mTLS" {...register("mTLS")} />
          mTLS .crs
        </div>
        <div>
          <input type="checkbox" value="SOAP" {...register("SOAP")} />
          SOAP Signing .csr
        </div>
        <div>
          <input type="checkbox" value="JWT" {...register("JWT")} />
          JWT Signing .csr
        </div>
      </div>

      <div className="uplodCSRs">
        <button className="btn">Upload Files</button>
      </div>

      <div />

      <div className="input-wrapper">
        <label htmlFor="ipAddress">IP Address</label>
        <input {...register("ipAddress")} />
      </div>

      <div className="radio">
        <label htmlFor="privacyStatement">Privacy Statement</label>
        <div>
          <input
            type="radio"
            {...register("PSyes")}
            value="PSyes"
            name="privacyStatement"
          />
          Yes
        </div>
        <div>
          <input
            type="radio"
            {...register("PSno")}
            value="PSno"
            name="privacyStatement"
          />
          No
        </div>
      </div>
      <input className="btn" type="submit" />
      <input className="btn" type="reset" />
    </form>
  )
}

export default RequestForm
