import React, { Fragment, useState, useRef, useEffect } from "react"
import ScrollspyNav from "react-scrollspy-nav"
import OpenIcon from "../assets/icons/Open.jsx"
import { createId } from "../utils/utils"

const PageIndexNav = ({ titles, offset, wideScreen }) => {
  const [isPageIndexOpen, setIsPageIndexOpen] = useState(wideScreen)
  const [titlesArray, setTitlesArray] = useState([])
  const [isSticky, setSticky] = useState(false)

  const pageIndexNavRef = useRef(null)

  titles.map(title => {
    if (!titlesArray.includes(createId(title))) {
      setTitlesArray(titlesArray => [...titlesArray, createId(title)])
    }
  })

  const handleScroll = () => {
    if (pageIndexNavRef.current) {
      setSticky(pageIndexNavRef.current.getBoundingClientRect().top <= 10)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      console.log("unmounted")
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [])

  return (
    <Fragment>
      <div
        className={`sticky-wrapper${isSticky ? " sticky" : ""}`}
        ref={pageIndexNavRef}
      >
        <div
          className="on-this-page"
          style={{ maxWidth: wideScreen ? "300px" : "unset" }}
        >
          <div
            className="title-arrow-wrap"
            onClick={() => setIsPageIndexOpen(!isPageIndexOpen)}
          >
            <h4>On this page</h4>
            {!wideScreen && (
              <div className={`toggle-icon ${isPageIndexOpen ? "open" : ""}`}>
                <OpenIcon />
              </div>
            )}
          </div>

          <div
            className={`article-section-wrap ${isPageIndexOpen ? "" : "close"}`}
            style={{ height: titles.length * 36 + "px" }}
          >
            <nav className="article-sections">
              <ScrollspyNav
                scrollTargetIds={titlesArray}
                offset={offset}
                activeNavClass="is-active"
                scrollDuration="500"
              >
                <ul className="titles">
                  {titles &&
                    titles.map((title, key) => {
                      return (
                        <li key={key}>
                          <a href={`#${createId(title)}`}>{title}</a>
                        </li>
                      )
                    })}
                </ul>
              </ScrollspyNav>
            </nav>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default PageIndexNav
