import React from "react"
import { Link } from "gatsby"

const ButtonBlock = ({ content }) => {
  console.log("content ", content.Button.text)
  return (
    <div className="button-block block">
      <div className="btn-wrap">
        {content.Button && (
          <Link className="btn" to={content.Button.link}>
            {content.Button.text}
          </Link>
        )}
      </div>
    </div>
  )
}

export default ButtonBlock
